import $ from 'jquery';

import { setup as setupShareLink } from 'JIX/share/link.js';
import * as shareNative from 'JIX/share/native.js';

const stashes = window.Stash['widgets/share_buttons'];

function init(id) {
    const $el = $('#' + id);
    if (shareNative.isAvailable()) {
        $el.find('.social-share-native').prop('hidden', false)
            .on('click', e => shareNative.share($(e.target)));
    } else {
        setupShareLink($el);
    }
    $el.on("click", ".social-share-print", () => window.print());
}

$(() => {
    stashes.forEach(({ id }) => init(id));
});
